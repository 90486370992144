<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">WINTER SPORTS TRAVEL INSURANCE</h1>
      </div>
      <div class="box b-shdw-3">
				<InfoButtonOptions :policyType="'WS'" :coverType="'WS'"/>

				<div class="content">
					<h2>Winter Sports Travel Insurance</h2>
					<p class="mb-5">
						If you are planning a skiing holiday with your family or a trip to Lapland to meet the huskies, then you should consider purchasing winter sports travel insurance to cover your trip.
						<br><br>
						Many resorts will offer a range of winter sports and activities to take part in, as well as skiing and snowboarding, so it is important to consider winter sports holiday insurance and the activities it covers, to ensure you are protected. 
					</p>

					<h2>What is winter sports travel insurance?</h2>
					<p class="mb-5">
						Winter sports travel insurance is designed specifically to cover winter sports trips and for those who enjoy skiing and snowboarding. Our winter sports cover will also cover you for other exciting winter activities, such as, snow rafting and snowblading.
					</p>

					<h2>How do I add winter sports cover to my policy?</h2>
					<p class="mb-5">
						Winter sports insurance can be added when you get a quote for travel insurance on our website. Our policy will still provide you with the usual benefits, such as, cancellation and delays, lost, stolen or damaged luggage, emergency medical expenses and personal liability. 
					</p>

					<h2>Our winter sports policies have the added benefits, such as:</h2>
					<p class="mb-5">
						Winters sports cover is an add-on to our policies, so you will benefit from all standard travel insurance coverage, such as, cancellation and curtailment, as well as: 
					</p>
					<p class="mb-5">
						<ul>
							<li>Lost, stolen, or delayed ski / snowboard equipment</li>
							<li>Loss of ski pass</li>
							<li>Piste / avalanche closure due to avalanche or lack of snow</li>
							<li>Personal liability, in case you injure someone else</li>
						</ul>
						<strong>
							Our winter sports policies cover the following, please check the Policy Wording for a
							full list of winter sports activities we cover:
						</strong>
						<ul>
							<li>Skiing (cross country/Nordic skiing on marked trails)</li>
							<li>Snowblading</li>
              <li>Snowboarding</li>
							<li>Tobogganing</li>
							<li>Ice-skating</li>
							<li>Sleigh riding</li>
						</ul>
					</p>

					<p class="mb-5">
						Some winter activities will be subject to terms and conditions, please refer to the Policy Wording for full details.
					</p>

					<h2>Frequently Asked Questions</h2>

					<!-- FAQs -->
					<b-collapse class="card" :open="false">
						<div slot="trigger" slot-scope="props" class="card-header" role="button">
							<p class="card-header-title">What is not covered by our winter sports insurance?</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<ul>
									<li>Your policy will be invalid if you do not wear a helmet</li>
									<li>Travelling against the advice of the government or the FCDO</li>
									<li>If you have not declared your pre-existing medical conditions</li>
									<li>If you have an accident while under the influence of alcohol or drugs</li>
								</ul>
								<p>For full terms, conditions and exclusions, please refer to our <a href="/policy-wordings-mul">Policy Wordings</a>.</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse class="card" :open="false">
						<div slot="trigger" slot-scope="props" class="card-header" role="button">
							<p class="card-header-title">Will an GHIC cover me for winter sports?</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<p>
									An EHIC or GHIC covers state healthcare, not private treatment.
									<br>
									With an EHIC or GHIC you can get emergency or necessary medical care for the same cost as a resident in the country you’re visiting. This means that you can get healthcare at a reduced cost or for free.
									<br>
									An EHIC or GHIC is not a replacement for travel insurance – it does not cover everything, such as mountain rescue or being flown back to the UK (medical repatriation). Make sure you have both before you travel.
									<br>
									You’ll need to pay in full for treatment if you do not have an EHIC, GHIC or provisional replacement certificate (PRC).
									<br>
									The following European countries do not accept the EHIC or GHIC:
								</p>
								<ul>
									<li>the Channel Islands, including Guernsey, Alderney and Sark</li>
									<li>the Isle of Man</li>
									<li>Monaco</li>
									<li>San Marino</li>
									<li>the Vatican</li>
								</ul>
							</div>
						</div>
					</b-collapse>

					<b-collapse class="card" :open="false">
						<div slot="trigger" slot-scope="props" class="card-header" role="button">
							<p class="card-header-title">Where can I travel with winter sports cover?</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<p>
									All our policies can cover you for trips to the UK, Europe and worldwide, so whether you’re planning a short snowboarding break in the alps, or a skiing trip in Canada, our policies can cover you. 
									<br><br>
									Medical costs can run into £100,000 plus if you have an accident and need to be returned home from the USA, so travel insurance with winter sports cover is especially important. 
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse class="card" :open="false">
						<div slot="trigger" slot-scope="props" class="card-header" role="button">
							<p class="card-header-title">When is the best time to purchase winter sports travel insurance?</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<p>
									As soon as you book. Our policies provide cover for cancellation risks, which means you can recoup the cost of your holiday should you need to cancel, due to falling ill, getting injured, or being made redundant. For more information about cancellation cover and the terms and conditions, please see our policy wordings. 
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse class="card" :open="false">
						<div slot="trigger" slot-scope="props" class="card-header" role="button">
							<p class="card-header-title">Should I get a couple’s policy?</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<p>
									If you are travelling as a couple, family or group, you may find that it is better value for money by having your partner or family on the same policy. For example, on our policies, couples benefit from a 10% discount, and kids are free on family policies. 
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse class="card" :open="false">
						<div slot="trigger" slot-scope="props" class="card-header" role="button">
							<p class="card-header-title">Can I get an annual multi-trip policy with winter sports cover?</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<p>
									Yes. If you are planning multiple trips throughout the year and one or more of them is a winter sports trip, then it’s important to add winter sports cover to your policy. You may even save money by purchasing a multi-trip policy versus buying separate single trip policies. 
								</p>
							</div>
						</div>
					</b-collapse>

					<b-collapse class="card" :open="false">
						<div slot="trigger" slot-scope="props" class="card-header" role="button">
							<p class="card-header-title">Can I get winter sports cover if I have pre-existing medical conditions?</p>
							<a class="card-header-icon">
								<b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
							</a>
						</div>
						<div class="card-content">
							<div class="content">
								<p>
									If your pre-existing medical conditions have been declared to our medical screening team, we may be able to cover you. <a href="https://www.covered2go.co.uk/pre-existing-medical-conditions">Click here</a> for more information on covering your pre-existing medical conditions.
								</p>
							</div>
						</div>
					</b-collapse>

					<br>
					Looking for winter sports travel insurance? <a href="https://www.covered2go.co.uk/get-quote">Get a quote</a> online today.

					<!-- <a href="/policy-wordings">policy wordings</a> -->
					<!-- <p class="mb-4">
						Looking for worldwide travel insurance? <a href="https://www.covered2go.co.uk/get-quote">Get a quote</a> online today.
					</p> -->
					
				</div>
				
				<InfoButtonOptions :policyType="'WS'" :coverType="'WS'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->

			</div>
    </div>
  </transition>
</template>

<script>
import InfoBoxes from "../components/common/InfoBoxes";
// import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'WorldSportTravelInsurance',
	data() {
		return {

		}
	},
	methods: {

	},
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	}
};
</script>

<style lang="scss" scoped>
	@import 'src/styles/views/info-page';
</style>
